'use client';
import React from 'react';
import { Alert, Card, ScrollArea } from '@mantine/core';
import AuditForm from '../Forms/AuditForm';

type SelfAuditPanelProps = {
  policyId: string | null;
  closePanel: () => void;
};

const SelfAuditPanel = ({ policyId, closePanel }: SelfAuditPanelProps) => {
  if (!policyId) {
    return <Alert>No Active Self Audit</Alert>;
  }

  return (
    <ScrollArea type="auto" h="100vh">
      <Card>
        <AuditForm policyId={policyId} closePanel={closePanel} />
      </Card>
    </ScrollArea>
  );
};

export default SelfAuditPanel;
