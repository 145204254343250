import { Avatar, Button, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React from 'react';
import { useForm } from 'react-hook-form';
import { MutationUpdateUserInputSchema } from '../../graphql/inputs.generated';
import { useUpdateUserMutation } from '../../graphql/operations/users.generated';
import { MutationUpdateUserInput } from '../../graphql/types.generated';
import onApolloError from '../../utils/utils';
import { closeAllModals } from '@mantine/modals';
import { isEmpty, isNil } from 'lodash';

const PHONE_NUMBER_REGEX = /^\D*(\d\D*){10}$/;

type UserFormProps = {
  initialValues?: Partial<MutationUpdateUserInput>;
};

const UserForm = ({ initialValues = {} }: UserFormProps) => {
  const [updateUser] = useUpdateUserMutation({
    onError: onApolloError(),
    onCompleted: () => {
      showNotification({ message: 'Updated user' });
      closeAllModals();
    },
  });
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<MutationUpdateUserInput>({
    defaultValues: initialValues,
  });

  const onSubmit = handleSubmit((data) =>
    updateUser({ variables: { input: MutationUpdateUserInputSchema().parse(data) } })
  );

  return (
    <SimpleGrid cols={1}>
      <Stack align="center">
        <Avatar src={initialValues.imageUrl} size={'120'}></Avatar>
      </Stack>
      <form onSubmit={onSubmit}>
        <Stack gap={'lg'}>
          <TextInput
            {...register('firstName')}
            label="First Name"
            required
            error={errors.firstName?.message}
          />
          <TextInput
            {...register('lastName')}
            label="Last Name"
            required
            error={errors.lastName?.message}
          />
          <TextInput {...register('title')} required label="Title" error={errors.title?.message} />

          <TextInput
            {...register('salesforceRecordId')}
            label="Salesforce Record ID"
            error={errors.salesforceRecordId?.message}
          />
          <TextInput
            {...register('npn')}
            label="NPN"
            description="Please provide your NPN if you are a licensed underwriter"
            error={errors.npn?.message}
          />
          <TextInput
            {...register('phoneNumber', {
              validate: (value) => {
                // do not validate if no value provided
                return isEmpty(value) || isNil(value) ? true : PHONE_NUMBER_REGEX.test(value);
              },
            })}
            label="Phone Number"
            description="Please provide your phone number if you are a licensed underwriter"
            error={errors.phoneNumber && 'Please provide a valid phone number'}
          />

          <Button type="submit" fullWidth loading={isSubmitting}>
            Save
          </Button>
        </Stack>
      </form>
    </SimpleGrid>
  );
};

export default UserForm;
