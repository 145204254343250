import numeral from 'numeral';
import { AuditSection } from '../../graphql/types.generated';
import React from 'react';
import { Text } from '@mantine/core';

type SelfAuditSectionScoreProps = {
  section: string;
  selfAudit: any;
};

/**
 * get the appropriate color for the audit-score text. Any score < 80% (0.8) should be
 * marked as red to indicate a non-passing score
 */
const getScoreColor = (score: number): string => {
  return score > 0.8 ? 'black' : 'red';
};

/**
 * given the audit section's score, convert it into a percentage, rounded to 0 decimal places
 */
const SelfAuditSectionScore = ({ section, selfAudit }: SelfAuditSectionScoreProps) => {
  switch (section) {
    case AuditSection.AccountServicing:
      return (
        <Text style={{ color: getScoreColor(selfAudit.accountServicingAuditScore) }} fw="bold">
          {numeral(selfAudit.accountServicingAuditScore).format('0%')}
        </Text>
      );
    case AuditSection.Documentation:
      return (
        <Text style={{ color: getScoreColor(selfAudit.documentationAuditScore) }} fw="bold">
          {numeral(selfAudit.documentationAuditScore).format('0%')}
        </Text>
      );
    case AuditSection.ProductIntegrityAndCompliance:
      return (
        <Text
          style={{ color: getScoreColor(selfAudit.productIntegrityAndComplianceAuditScore) }}
          fw="bold"
        >
          {numeral(selfAudit.productIntegrityAndComplianceAuditScore).format('0%')}
        </Text>
      );
    case AuditSection.UnderwritingQuality:
      return (
        <Text style={{ color: getScoreColor(selfAudit.underwritingQualityAuditScore) }} fw="bold">
          {numeral(selfAudit.underwritingQualityAuditScore).format('0%')}
        </Text>
      );
    case 'Total':
      return (
        <Text style={{ color: getScoreColor(selfAudit.totalSelfAuditScore) }} fw="bold">
          Total Self-Audit Score: {numeral(selfAudit.totalSelfAuditScore).format('0%')}
        </Text>
      );
    default:
      return <>No Score Available</>;
  }
};

export default SelfAuditSectionScore;
