import React, { useRef, useCallback, ReactNode, useMemo } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

export const ConfettiContext = React.createContext<{ fireConfetti: () => void }>({
  fireConfetti: () => undefined,
});

const ConfettiProvider = ({ children }: { children: ReactNode }) => {
  const refAnimationInstance = useRef(null);
  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);
  const makeShot = useCallback(
    (particleRatio: any, opts: any) =>
      refAnimationInstance.current &&
      //@ts-ignore
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      }),
    []
  );

  const fireConfetti = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const value = useMemo(() => ({ fireConfetti }), [fireConfetti]);

  return (
    <>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      />
      <ConfettiContext.Provider value={value}>{children}</ConfettiContext.Provider>
    </>
  );
};

export default ConfettiProvider;
