import { snakeCase } from 'lodash';
import { ExposureType } from '../graphql/types.generated';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEveryFirstLetter = (str: string) => {
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  });

  return capitalizedWords.join(' ');
};

const humanizeString = (string: string) => {
  const newString = snakeCase(string)
    .toLowerCase()
    .replace(/[_-]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();

  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const humanizeEnum = (enumValue: string) => {
  const lowered = enumValue.toLowerCase();
  const split = lowered.split('_');
  const capitalized = split.map((item) => capitalizeFirstLetter(item));
  return capitalized.join(' ');
};

export const humanizeStringEveryUpper = (string: string) => {
  return humanizeString(string)
    .split(' ')
    .map((item) => capitalizeEveryFirstLetter(item))
    .join(' ');
};

export const humanizeStringFirstUpper = (string: string) => {
  return humanizeString(string)
    .split(' ')
    .map((item) => capitalizeFirstLetter(item))
    .join(' ');
};

export const humanizeExposureName = (exposureType: ExposureType): string => {
  switch (exposureType) {
    case ExposureType.GeneralLiability:
      return 'GL';
    case ExposureType.Auto:
      return 'Auto';
    case ExposureType.OwnersAndContractorsProtective:
      return 'Owners and Contractors Protective';
    case ExposureType.Railroad:
      return 'Railroad';
    default:
      return '';
  }
};
export default humanizeString;
