import { Group, Title, Breadcrumbs, Anchor, Stack, TitleOrder } from '@mantine/core';
import Link from 'next/link';
import React from 'react';

import { IconChevronRight } from '@tabler/icons-react';
import { Route } from 'next';
import { isNil } from 'lodash';

const TITLE_ORDER = 5;

type PageHeaderProps = {
  title: { label: string; href?: string; width?: string };
  order?: TitleOrder;
  precedingLinks?: Array<{ name: string; href: string }>;
  rightSection?: React.ReactNode;
  subSection?: React.ReactNode;
};

const PageTitle = ({
  label,
  href,
  order,
  width = '35rem',
}: {
  label: string;
  href?: string;
  order?: TitleOrder;
  width?: string;
}) => {
  return (
    <Anchor href={!isNil(href) ? (href as Route) : ''} component={Link}>
      <Title
        order={order ?? TITLE_ORDER}
        c="gray"
        key={`breadcrumb-${label}`}
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: width,
        }}
      >
        {label}
      </Title>
    </Anchor>
  );
};

const PageHeader = ({
  title,
  order,
  precedingLinks,
  rightSection,
  subSection,
}: PageHeaderProps) => {
  return (
    <Stack gap="xs" pb="xs">
      <Group justify="space-between" align="center">
        <Breadcrumbs
          separatorMargin="0.5rem"
          separator={<IconChevronRight color="var(--mantine-color-gray-6)" size={16} />}
        >
          {precedingLinks?.map((link) => (
            <Anchor
              c="gray"
              component={Link}
              href={link.href as Route}
              key={`breadcrumb-${link.name}`}
            >
              <Title
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '12rem',
                }}
                order={order ?? TITLE_ORDER}
              >
                {link.name}
              </Title>
            </Anchor>
          ))}
          {title && (
            <PageTitle label={title.label} href={title.href} order={order} width={title.width} />
          )}
        </Breadcrumbs>
        {rightSection}
      </Group>
      <Group gap="xs">{subSection}</Group>
    </Stack>
  );
};

export default PageHeader;
