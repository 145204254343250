import { ActionIcon, Tooltip } from '@mantine/core';
import React, { ReactNode } from 'react';

const ShepherdActionIcon = ({
  onClick,
  tooltip,
  loading,
  disabled,
  icon: Icon,
}: {
  onClick: () => void;
  tooltip?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  icon: React.ElementType;
}) => {
  const item = (
    <ActionIcon
      onClick={onClick}
      size="compact-xs"
      variant="white"
      loading={loading}
      disabled={disabled}
    >
      <Icon width={16} height={16} />
    </ActionIcon>
  );

  if (tooltip) {
    return <Tooltip label={tooltip}>{item}</Tooltip>;
  }

  return item;
};

export default ShepherdActionIcon;
