import { create } from 'zustand';

type SelfAuditToggle = {
  open: boolean;
  activePolicyId: string | null;
  enterSelfAuditView: (policyId: string) => void;
  openPanel: () => void;
  closePanel: () => void;
};

const useSelfAuditToggle = create<SelfAuditToggle>((set) => ({
  open: false,
  activePolicyId: null,
  enterSelfAuditView: (policyId: string) => set({ open: true, activePolicyId: policyId }),
  openPanel: () => set({ open: true }),
  closePanel: () => set({ open: false }),
}));

export default useSelfAuditToggle;
