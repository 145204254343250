import { Tooltip } from '@mantine/core';
import { IconAlertTriangle, IconChecks, IconQuestionMark } from '@tabler/icons-react';
import { isNil } from 'lodash';
import React from 'react';
import { AuditScore } from '../../graphql/types.generated';

const ICON_SIZE = 14;

type SelfAuditAutoDetectIconProps = {
  autoDetectValue: string | null | undefined;
};

const SelfAuditAutoDetectIcon = ({ autoDetectValue }: SelfAuditAutoDetectIconProps) => {
  if (isNil(autoDetectValue)) {
    return null;
  }

  if (autoDetectValue === AuditScore.NoInformation) {
    return (
      <Tooltip label={'Upload required'} multiline w={120} position="top">
        <IconAlertTriangle size={ICON_SIZE} color="var(--mantine-color-red-6)" />
      </Tooltip>
    );
  } else if (autoDetectValue === AuditScore.StronglyAgree) {
    return (
      <Tooltip label={'Documentation detected'} multiline w={175} position="top">
        <IconChecks size={ICON_SIZE} color="var(--mantine-color-green-6)" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip label={'Documentation could not be determined'} multiline w={200} position="top">
        <IconQuestionMark size={ICON_SIZE} color="var(--mantine-color-orange-6)" />
      </Tooltip>
    );
  }
};

export default SelfAuditAutoDetectIcon;
